export const riskHomeConfigVO = function () {
  return {
    config: {
      id: null,
      organization: {
        id: null
      },
      colorImageOneContent: ''
    },
    imageIds: [],
    images: []
  }
}